import React from 'react';
import { useSelector } from 'react-redux';
import Header from '../_core/HeaderLeft';
import UnderlineLink from '../_core/UnderlineLink';
import LoginMenuItem from 'components/LoginMenuItem';

import Wrapper from '../_core/Wrapper';
import Hero from './Hero';
import CourseModal from 'components/Courses/CourseModal';

import Footer from '../_core/Footer';
import { getEnvVar } from 'utils/get-env-var';
import SaleBanner from './SaleBanner';
import { TRIAL_DAYS } from 'utils/constants';
import { SubscribeBtn } from 'components/buttons';

import FeaturedCouses from './FeaturedCourses';
import ImprovIsSkill from './ImprovIsSkill';
import UniversityRigor from './UniversityRigor';
import NotEnterainment from './NotEntertainment';
import FreeTrailBar from './FreeTrialBar';
import AllLevels from './AllLevels';
import MeetJP from './MeetJP';
import PersonalizedFeedback from './PersonalizedFeedback';
import FacebookCommunity from './FacebookCommunity';
import RhythmBot from './RhythmBot';
import FinalWords from './FinalWords';
import Courses from 'components/Courses';

const navItems = [
    () => <li><UnderlineLink to={`${getEnvVar('ROOT_ROUTE')}/courses`}>Courses</UnderlineLink></li>,
    () => <li><LoginMenuItem /></li>,
    () => <li><SubscribeBtn pill small lighterGray>{TRIAL_DAYS > 0 ? '7 Days Free' : 'Join Now'}</SubscribeBtn></li>
];

const Home = () => {
    const isSubscriber = useSelector(state => state.user.userData.isSubscriber);
    const activeSale = useSelector(state => state.sales.sale);

    if (isSubscriber) {
        return <Courses />;
    }
    
    return (
        <>
            <SaleBanner />
            <Wrapper>
                <Header
                    hideLogoUntilScroll 
                    navItems={navItems} 
                    hasActiveSaleBanner={activeSale && activeSale.banner_text}
                />
            </Wrapper>
            
            <Hero isSubscriber={isSubscriber} />
            <FeaturedCouses />
            <FreeTrailBar />
            <MeetJP />
            <ImprovIsSkill />
            <FreeTrailBar />
            <UniversityRigor />
            <NotEnterainment />
            <FreeTrailBar />
            <AllLevels />
            <PersonalizedFeedback />
            <FacebookCommunity />
            <RhythmBot />
            <FinalWords />
            
            <Footer />
            <CourseModal />
        </>
    );
};

export default Home;