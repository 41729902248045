import { connect } from 'react-redux';
import CourseModal from './CourseModal';

import { closeCourseModal, playCoursePreview, stopCoursePreview, copyModalCourse, toggleLoginModal } from 'redux/actions';

const mapStateToProps = (state, props) => (
    {
        ...props,
        open: state.courseList.courseModal.open,
        courseSlug: state.courseList.courseModal.slug,
        courseName: state.courseList.courseModal.name,
        course: state.courseList.courseModal.modalCourse,
        playingPreview: state.courseList.courseModal.playingPreview,
        isSubscriber: state.user.userData.isSubscriber,
        loggedIn: state.user.auth.loggedIn
    }
);

const actions = {
    closeCourseModal,
    playCoursePreview,
    stopCoursePreview,
    copyModalCourse,
    toggleLoginModal
};

export default connect(mapStateToProps, actions)(CourseModal);