import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Message from './_core/Message';
import InlineLoader from './_core/InlineLoader';

import WhiteContainer from './_core/WhiteContainer';
import Header from './_core/Header';
import UnderlineLink from './_core/UnderlineLink';
import LoginMenuItem from 'components/LoginMenuItem';
import { getEnvVar } from 'utils/get-env-var';

import Wrapper from './_core/Wrapper';
import Footer from './_core/Footer';
import Accordian from './_core/Accordian';

const navItems = [
    () => <li className="hide-mobile"><UnderlineLink to={`${getEnvVar('ROOT_ROUTE')}/courses`}>Courses</UnderlineLink></li>,
    () => <li><LoginMenuItem /></li>
];

const StaticPageContainer = styled(WhiteContainer)`

margin: 100px auto 60px;

h1 {
    margin-top: 0;
}
`;

const PageWrapper = ({ children }) => (
    <>
        <Wrapper>
            <Header navItems={navItems} />
            <StaticPageContainer>
                <h1>FAQs</h1>
                {children}
            </StaticPageContainer>
        </Wrapper>
        <Footer />
    </>
);

const ItemWrapComponent = styled.div`
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 20px 15px;
    margin: 10px 0;
    color: #333;
    cursor: pointer;

    p {
        font-size: 15px;
    }
`;

const ItemHeaderWrap = styled.div`
    h2 {
        font-size: 19px;
    }

    padding-right: 50px;

    position: relative;

    &::after {
        // chevron down
        content: '';
        position: absolute;
        top: 50%;
        right: 10px;
        width: 15px;
        height: 15px;
        border: 3px solid #666;
        border-top: none;
        border-right: none;
        transform: translateY(-50%);
        transition: transform 0.3s;
        transform: ${props => props.active ? 'translateY(-50%) rotate(135deg)' : 'translateY(-50%) rotate(-45deg)'};
    }
`;

const ItemHeader = ({ heading, onClick, active }) => (
    <ItemHeaderWrap active={active} onClick={() => onClick()}>
        <h2>{heading}</h2>
    </ItemHeaderWrap>
);

const ItemContent = ({ copy }) => (
    <p dangerouslySetInnerHTML={{ __html: copy }} />
);

const Faqs = () => {
    const [faqs, setFaqs] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        axios.get(`${getEnvVar('API_BASE')}/faqs`)
            .then(fetchResponse => setFaqs(fetchResponse.data))
            .catch(err => setError(err));
    }, [setFaqs]);  
    
    if (error) {
        return <PageWrapper><Message error>An unexpected error occured</Message></PageWrapper>;
    }

    if (!faqs) {
        return <PageWrapper><InlineLoader /></PageWrapper>;
    }

    return (
        <PageWrapper>
            <Accordian 
                sections={faqs} 
                HeaderComponent={ItemHeader} 
                ItemsComponent={ItemContent}
                ItemWrapComponent={ItemWrapComponent} 
                internalState={true}
            />
        </PageWrapper>
    );
}

export default Faqs;
