import React, { useState } from 'react';
import styled from 'styled-components';
import testimonials from './testimonials.json';
import arrowLeft from 'img/landingpage/puddin-arrow-left.png';
import arrowRight from 'img/landingpage/puddin-arrow-right.png';
import { TestiGrid, Testimonial } from '../Home/TestimonialGrid';

const Container = styled.div`
    background-color: #ffffff;
    padding: 0 0 60px;
    position: relative;

    &::before {
        content: '';
        display: block;
        background-color: #fff;
        position: absolute;
        width: 105%;
        left: 0;
        top: 0;
        height: 300px;
        rotate: -3.5deg;
        transform-origin: top left;
        z-index: 1;

        @media screen and (max-width: 400px) {
            display: none;
        }
    }
`;

const Wrapper = styled.div`
    max-width: 900px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    top: -80px;
    text-align: center;
    margin-bottom: -120px;
`;

const Header = styled.div`
    text-align: center;
    padding: 0 15px;
    margin: 0 auto 40px;
    max-width: 450px;
    position: relative;

    h2 {
        display: inline-block;
        background-color: #f7f93d;
        color: #000;
        font-size: 3.8rem;
        font-weight: 900;
        text-transform: uppercase;
        padding: 0 20px;
        width: 100%;
        white-space: nowrap;
        margin: 0 0 20px 0;

        position: relative;

        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0.25;
            top: 5px;
            right: 5px;
            z-index: -1;
        }
    }

    h3 {
        margin: 0 0 15px 0;
        font-size: 28px;
        font-weight: 600;

        @media screen and (max-width: 550px) {
            display: none;
        }
    }

    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2em;

        @media screen and (max-width: 550px) {
            display: none;
        }
    }
`;

const Arrow = styled.img`
    position: absolute;
    bottom: 0;
    width: 25px;

    @media screen and (max-width: 550px) {
        display: none;
    }
`;
const ArrowLeft = styled(Arrow)`
    left: -25px;
`;
const ArrowRight = styled(Arrow)`
    right: -25px;
`;

const QuoteWrap = styled.div`
    display: flex;
    border: 1px solid #ccc;

    @media screen and (max-width: 550px) {
        display: none;
    }
`;

const Menu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
`;

const MenuItem = styled.button`
    padding: 15px 30px;
    cursor: pointer;
    background-color: #e2eff1;
    color: #4a4a4a;
    border: none;
    text-align: left;
    font-size: 20px;
    font-family: 'Open Sans',sans-serif;
    font-weight: 600;
    white-space: nowrap;
    flex-basis: 20%;


    &:hover {
        background-color: #077484;
        color: #fff;
    }

    ${({ selected }) => selected && `
        background-color: #0c5460 !important;
        color: #fff;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translateY(-50%);

            // arrow pointing right
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 12px solid #0c5460;
        }
    `}
`;

const QuoteTable = styled.div`
    max-height: 400px;
    overflow-y: auto;
    text-align: left;
`;

const Quote = styled.div`
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;

    &:nth-child(even) {
        background-color: #f7f7f7;
    }
`;

const QuoteText = styled.div`
    color: #4a4a4a;
    flex-basis: 70%;
`;

const QuoteAuthor = styled.div`
    color: ${props => props.theme.colors.teal};
    flex-basis: 20%;
    flex-shrink: 0;

    &::before {
        content: '- ';
    }
`;

const Mobile = styled(TestiGrid)`
    display: none;

    @media screen and (max-width: 550px) {
        padding: 0 20px;
        font-size: 16px;

        display: block;
    }
`;

const Proof = ({ Button }) => {
    const [selectedCategoryIdx, setSelectedCategoryIdx] = useState(0);

    return (
        <Container>
            <Wrapper>
                <Header>
                    <h2>Proof</h2>
                    <h3>ANYONE Can Improvise</h3>
                    <p>Below are hundreds of testimonials from drummers of <strong>all levels, all ages, and all genres</strong>, who have found freedom, mastery, and joy with JPBMethod.</p>
                    <ArrowLeft src={arrowLeft} />
                    <ArrowRight src={arrowRight} />
                </Header>
                <Mobile>
                    <Testimonial>
                        <p>“<span className="highlight">The freedom I've aquired</span> on the drum set in the past 16 weeks is insane.”</p>
                        <p className="teal">&mdash; Brandon G.</p>
                    </Testimonial>

                    <Testimonial>
                        <p>“The website took my ability to improvise and <span className="highlight">increased it tenfold</span>.”</p>
                        <p className="teal">&mdash; Nicholas H.</p>
                    </Testimonial>
                    <Testimonial>
                        <p>“People talk a lot about soloing as storytelling, but I feel like <span className="highlight">I actually get what that means now</span>.”</p>
                        <p className="teal">&mdash; Will R.</p>
                    </Testimonial>
                </Mobile>
                <QuoteWrap>
                    <Menu>
                        {testimonials.map(({ category_name }, idx) => (
                            <MenuItem key={idx} onClick={() => setSelectedCategoryIdx(idx)} selected={selectedCategoryIdx===idx}>{category_name}</MenuItem>
                        ))}
                    </Menu>
                    <QuoteTable>
                        {testimonials[selectedCategoryIdx].testimonials.map(({ testimonial, byline }, idx) => (
                            <Quote key={idx}>
                                <QuoteText>{testimonial}</QuoteText>
                                <QuoteAuthor>{byline}</QuoteAuthor>
                            </Quote>
                        ))}
                    </QuoteTable>
                </QuoteWrap>
                <Button />
            </Wrapper>
        </Container>
    );
};

export default Proof;
