import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Message from 'components/_core/Message';

import { faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons';

import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = "https://jpbouvetmusic.us6.list-manage.com/subscribe/post?u=0874dfdae8aad219583aafc58&amp;id=72a1b7a97b";

const FormWrapper = styled.div`
    border-radius: 7px;
    border: 1px solid #bbb;
    padding: 10px 30px 10px 7px;
    position: relative;
`;

const EmailInput = styled.input`
    appearance: none;
    background: none;
    border: none;
    font-size: 18px;
    font-weight: 200;
    outline: none;
    color: #eee;
    font-family: 'Open Sans',sans-serif;
    width: 100%;
`;

const ChevronButton = styled.button`
    appearance: none;
    background: none;
    border: none;
    color: #bbb;
    outline: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
`;

export default () => {
    const [email, setEmail] = useState('');


    const submitForm = (e, subscribe) => {
        e.preventDefault();
        subscribe({ EMAIL: email, SITESUBTYP: 'none' });
    };

    return <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => {
            const processing = status === 'sending';

            if (status === 'success') {
                return <Message success small>Thank you for subscribing</Message>;
            }

            if (message === '0 - Please enter a value' || message === '0 - An email address must contain a single @') {
                message = 'Please enter a valid email address';
            }

            return (
                <div className="footer-mc">
                    {status === "error" && <Message small noIcon><span dangerouslySetInnerHTML={{ __html: message }} /></Message>}
                    <form onSubmit={e => submitForm(e, subscribe)}>
                        <FormWrapper>
                            <EmailInput type="email" value={email} disabled={processing} onChange={e => setEmail(e.target.value)} placeholder="Email Address" />
                            {!processing ? <ChevronButton type="submit"><FontAwesomeIcon icon={faChevronRight} /></ChevronButton> : <FontAwesomeIcon icon={faSpinner} spin />}
                        </FormWrapper>
                    </form>
                </div>
            );
        }}
    />;
};
