import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from 'components/_core/Button';
import FavoriteBtn from './FavoriteBtn';
import ActionsMenu from './ActionsMenu';
import TabView from 'components/_core/TabView';
import { CourseMetaData as CourseMetaDataCore, CourseLevel, CourseLength } from 'components/_core/CourseMetaData';
import NextSteps from './NextSteps';
import { LEVEL_MAPPING } from 'utils/constants';
import { getEnvVar } from 'utils/get-env-var';

import { firstVideoOfCourse } from 'redux/selectors/first-video-of-course';
import { nextVideoOfCourse } from 'redux/selectors/next-video-of-course';
import { setCourseDetailsTab, setCurrentVideo } from 'redux/actions';
import ProgressPostDetails from './ProgressPostDetails';

const CourseDetailsWrapper = styled.div`
    padding: 0 30px;
`;

const CourseDetails = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
`;

const CourseSection = styled.div`
    color: #ddd;
    max-width: 800px;
    margin-top: 30px;
    min-height: 200px;
`;

const SectionHeading = styled.h2`
    margin: 20px 0 10px;
    color: #ddd;
`;

const CourseMetaData = styled(CourseMetaDataCore)`
    justify-content: flex-start;

    ${CourseLevel} {
        margin-right: 20px;
    }
`;

const CourseActions = styled.div`
    display: flex;
    justify-content: flex-end;

    @media screen and (min-width: 1001px) {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0 -3px;
    }
`;

const BookBtn = styled.div`
`;

const NextVideo = styled.div`
    margin-top: 30px;
    cursor: pointer;
`;

const NextVideoHeading = styled.p`
    text-transform: uppercase;
    font-size: 12px;
    margin: 0;
    color: ${props => props.theme.colors.orange};
`;

const NextVideoName = styled.p`
    margin: 0;
    font-size: 16px;
    color: #eee;
`;

export default ({ course, ...props }) => {
    const dispatch = useDispatch();

    const currentVideo = useSelector(state => state.currentCourse.currentVideo);
    const firstVideo = useSelector(state => firstVideoOfCourse(state, { course }));
    const nextVideo = useSelector(state => nextVideoOfCourse(state, { course }));
    const activeTab = useSelector(state => state.currentCourse.courseDetails.activeTab);
    
    const sections = [
        {
            title: 'Course Overview',
            Content: () => (
                <div style={{ position: 'relative' }}>
                    <CourseSection>
                        {course?.booklet && <BookBtn>
                            <Button teal onClick={() => window.open(`${getEnvVar('PDF_PATH')}/${course.booklet}`)}>Course Book</Button>
                        </BookBtn>}
                        <SectionHeading>{course.name}</SectionHeading>
                        <CourseMetaData>
                            <CourseLevel level={course.level}>{LEVEL_MAPPING[course.level]}</CourseLevel>
                            <CourseLength>{course.videoCount} Videos</CourseLength>
                        </CourseMetaData>
                        <p dangerouslySetInnerHTML={{ __html: course.description }} />

                        {firstVideo && <Button orange onClick={() => dispatch(setCurrentVideo(firstVideo))}>Start Course</Button>}
                    </CourseSection>
                </div>
            )
        }
    ];

    if (currentVideo && currentVideo.id && !currentVideo.isPreview && currentVideo.type !== 'progress') {
        sections.push({
            title: 'Current Lesson',
            Content: () => (
                <div style={{ position: 'relative' }}>
                    <CourseSection>
                        <CourseActions>
                            <FavoriteBtn />
                            <ActionsMenu circle={true} video={currentVideo} course={course} />
                        </CourseActions>

                        <SectionHeading>{currentVideo.name}</SectionHeading>
                        {currentVideo.description && <p dangerouslySetInnerHTML={{ __html: currentVideo.description }} />}
                        
                        {nextVideo && (
                            <NextVideo onClick={() => dispatch(setCurrentVideo(nextVideo))}>
                                <NextVideoHeading>Up Next:</NextVideoHeading>
                                <NextVideoName>{nextVideo.name}</NextVideoName>
                            </NextVideo>
                        )}
                    </CourseSection>
                </div>
            )
        });
    }

    if (currentVideo && currentVideo.id && !currentVideo.isPreview && currentVideo.type === 'progress') {
        sections.push({
            title: 'Progress Post',
            Content: () => (
                <div style={{ position: 'relative' }}>
                    <CourseSection>
                        <SectionHeading>{currentVideo.name}</SectionHeading>
                        <ProgressPostDetails currentVideo={currentVideo} {...props} />
                        {nextVideo && (
                            <NextVideo onClick={() => dispatch(setCurrentVideo(nextVideo))}>
                                <NextVideoHeading>Up Next:</NextVideoHeading>
                                <NextVideoName>{nextVideo.name}</NextVideoName>
                            </NextVideo>
                        )}
                    </CourseSection>
                </div>
            )
        });
    }

    return (
        <CourseDetailsWrapper>
            <CourseDetails>
                <TabView activeTab={activeTab} setActiveTab={tab => dispatch(setCourseDetailsTab(tab))} sections={sections} />

                <NextSteps course={course} />
            </CourseDetails>
        </CourseDetailsWrapper>
    );
};