import React from "react";
import styled from "styled-components";
import { TwoGrid } from "components/_core/grids";
import { Testimonial as T } from "components/Home/TestimonialGrid";

const Wrap = styled.div`
    margin-top: 15px;
    border-top: 1px solid #eee;
    padding-top: 25px;
`;  

const Testimonial = styled(T)`
    p {
        font-size: 14px;
    }
`;

const Testimonials = ({ testimonials }) => {
    if (!testimonials || testimonials.length === 0) {
        return null;
    }

    return (
        <Wrap>
            <TwoGrid>
                {testimonials.map((testimonial, idx) => (
                    <Testimonial key={idx}>
                        <p>“{testimonial.quote}”</p>
                        <p className="teal byline">&mdash; {testimonial.byline}</p>
                    </Testimonial>

                ))}
            </TwoGrid>
        </Wrap>
    );
};

export default Testimonials;
